import './styles/index.scss';
import React, { useState } from 'react';
import { useMount } from 'react-use';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, notification } from 'antd';

import HeaderL2 from '../../components/header-l2';
import Menu from '../../uiKitComponents/menu';
import EnabledHeader from './EnabledHeader';
import LandingsList from './LandingsList';
import QuickActions from '../SinglePadPage/quickActions';
import { quickAccessSetPad, quickAccessSetReviews } from '../../store/actions/padQuickAccessAction';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import { setIsUpdateData, setPadLandingsCounter, setPadLandingsToggle } from '../../store/actions/padLandingsActions';
import api from '../../utils/appApi';


const SinglePadPage = () => {
  const { pad_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [pad, setPad] = useState(null);

  const {
    padQuickAccess,
  } = useSelector((store) => ({
    padQuickAccess: store.padQuickAccess?.payload,
  }));

  const fetchErrorsHandler = (data) => {
    notification.error({
      message: 'Error',
      description: getApiErrorMessages(data.reason),
    });

    setTimeout(() => {
      history.push('/my-sites');
    }, 500);
  };

  const fetchPad = () => {
    Promise.allSettled([
      api.pad.get(pad_id),
      api.reviews.get(pad_id, { direction: 'desc', page: 1 }),
    ]).then(([fetchedPad, fetchedReviews]) => {
      if (fetchedPad.status === 'fulfilled') {
        setPad(fetchedPad.value.data.data);
        quickAccessSetPad(fetchedPad.value.data.data)(dispatch);
        setPadLandingsCounter(fetchedPad.value.data.data.landings_count)(dispatch);
        setPadLandingsToggle(fetchedPad.value.data.data.enabled_landings)(dispatch);
        setIsUpdateData(true)(dispatch);
      } else {
        fetchErrorsHandler(fetchedPad);
      }

      if (fetchedReviews.status === 'fulfilled') {
        quickAccessSetReviews(fetchedReviews.value.data)(dispatch);
      }
    });
  };

  useMount(() => {
    if (pad_id) {
      if (
        parseInt(pad_id, 10) === padQuickAccess.pad?.id &&
        padQuickAccess.reviews?.stars
      ) {
        setPad(padQuickAccess.pad);
      } else {
        fetchPad();
      }
    } else {
      history.push('/my-sites');
    }
  });

  return (
    <div className="site-view">
      <HeaderL2 backPath="/my-sites">
        <div className="site-view_header-nav">
          <Menu mode="horizontal" defaultSelectedKeys={['single-site_landing']}>
            <Menu.Item key="single-site_view">
              <Link to={`/my-sites/site/${pad_id}`}>Site View</Link>
            </Menu.Item>
            <Menu.Item key="single-site_reviews">
              <Link to={`/my-sites/reviews/${pad_id}`}>Reviews & Ratings</Link>
            </Menu.Item>
            <Menu.Item key="single-site_landing">Landings</Menu.Item>
          </Menu>
        </div>
        <div className="site-view_header-actions">{null}</div>
      </HeaderL2>
      <div className="site-reviews">
        <div className="small-container">
          {!pad?.is_owned_by_current_user && <QuickActions pad={pad} fetchPad />}
          <EnabledHeader />
          <Divider />
          <LandingsList />
        </div>
      </div>
    </div>
  );
};

export default SinglePadPage;
