import { Col, notification, Row, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  setIsUpdateData,
  setPadLandingsCounter,
  setPadLandingsToggle,
  setSurveyStatus,
} from '../../store/actions/padLandingsActions';
import api from '../../utils/appApi';
import { QuestionCircleOutlined } from '@ant-design/icons';

const EnabledHeader = () => {
  const { pad_id } = useParams();
  const dispatch = useDispatch();
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const { counter, toggle, isUpdateData, padQuickAccess } = useSelector(
    (store) => ({
      counter: store.padLandings?.payload.counter,
      toggle: store.padLandings?.payload.toggle,
      isUpdateData: store.padLandings?.payload.isUpdateData,
      padQuickAccess: store.padQuickAccess?.payload,
    }),
  );

  useEffect(() => {
    if (!isUpdateData) {
      setLoadingSwitch(true);
      api.pad
        .get(pad_id)
        .then((res) => {
          setPadLandingsToggle(res.data.data.enabled_landings)(dispatch);
          setPadLandingsCounter(res.data.data.landings_count)(dispatch);
          setIsUpdateData(true)(dispatch);
          if (res.data.data.enabled_landings) {
            setSurveyStatus({
              completedSurevyStep: res.data.data.survey_completed_steps_count,
              surveyStatus: res.data.data.survey_status,
            })(
              dispatch,
            );
          }
        })
        .finally(() => {
          setLoadingSwitch(false);
        });
    }
  }, [dispatch, isUpdateData, pad_id]);

  const checkedSwitchHandler = () => {
    setLoadingSwitch(true);
    api.landings
      .setToggle(pad_id)
      .then((res) => {
        setPadLandingsToggle(res.data.enabled_landings)(dispatch);
        setPadLandingsCounter(res.data.landings_count)(dispatch);
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again later.',
        });
      })
      .finally(() => {
        setLoadingSwitch(false);
      });
  };

  return (
    <Row justify="space-between" align="middle" gutter={[16, 16]}>
      <Col span={12} md={12} sm={24} xs={24}>
        <h1 className="v1-h1 text-center-md">Landings {`(${counter})`}</h1>
      </Col>
      <Col
        span={12}
        md={12}
        sm={24}
        xs={24}
        className="d-flex align-items-center justify-content-end"
      >
        <div className="site-reviews_sort full-width-md">
          Enabled
          <Tooltip title={(
            <>
            <QuestionCircleOutlined style={{ marginRight: "10px", color: "orange"}}/>
            {`Enable or disable public access to the landing history`}
            </>
          )}>
            <Switch
                checked={
                    !padQuickAccess?.pad?.is_owned_by_current_user ? false : toggle
                }
              onClick={checkedSwitchHandler}
              disabled={!padQuickAccess?.pad?.is_owned_by_current_user}
              loading={loadingSwitch}
            />
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};

export default EnabledHeader;
