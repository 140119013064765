import { Button, Col, Empty, notification, Row, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Comment from '../../assets/icons/comment.svg';
import Fee from '../../assets/icons/fee.svg';
import Star from '../../assets/icons/star.svg';
import Placeholder from '../../assets/images/placeholder-image.png';
import api from '../../utils/appApi';
import { fileDownload } from '../../utils/fileDownload';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import getPadIcon from '../../utils/getPadIcon';
import ProgressLine from '../ProgressLine';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const MySurveys = (props) => {
  const { surveyList, setSurveyList, loading, setLoading } = props;

  const history = useHistory();

  const [isDownloading, setIsDownloading] = useState(false);
  const [PDFDownloadId, setPDFDownloadId] = useState(null);

  const { categories } = useSelector((store) => ({
    categories: store.bootstrap?.payload?.categories,
  }));

  const getStringRedaction = (str) =>
    (str && str.length > 190 && str.slice(0, 190).concat('...')) || str;
  const getStringRedactionData = (data) =>
    moment(data).isValid()
      ? getStringRedaction(moment(data).format(' Do MMM YYYY') || '-')
      : '-';
  const redirectToSurvey = (id) => history.push(`/survey-360/survey/${id}`);

  const getPadCategoryName = (id) => {
    let value;
    if (categories) {
      const categoryName = categories.find((item) => item.id === id);
      if (categoryName?.display_name === 'Hotel') {
        value = categoryName?.category_type[1]?.display_name;
      } else {
        value = categoryName?.category_type[0]?.display_name;
      }
    }
    return value;
  };

  //pad.survey_status
  const SURVEY_STARTED = 1;
  const SURVEY_IN_PROGRESS = 2;
  const SURVEY_COMPLETED = 3;

  const downloadPDF = useCallback(async (id) => {
    setIsDownloading(true);
    setPDFDownloadId(id);

    try {
      const survey = await api.survey.downloadPDFData(id);
      const pdf = await axios.post(
        'https://pdf.helipaddy.com/',
        { ...survey.data },
        {
          responseType: 'blob',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        },
      );

      fileDownload(
        pdf.data,
        `Survey_${survey.data?.general?.name?.replace(/\s/g, '_')}.pdf`,
        pdf.headers['content-type'],
      );
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setIsDownloading(false);
      setPDFDownloadId(null);
    }
  }, []);

  return (
    <>
      {surveyList.length ? (
        surveyList.map((item) => (
          <div key={item.id} className="surveys-item__content">
            <Row
              justify="space-between"
              align="middle"
              className="surveys-item"
            >
              <Col span={9} sm={9} xs={24}>
                <div className="surveys-item__content-img">
                  <img
                    src={
                      (item?.pad?.upload?.length &&
                        item?.pad?.upload[0].file_url) ||
                      Placeholder
                    }
                    alt={item.name}
                  />
                </div>
              </Col>
              <Col span={15} sm={15} xs={24}>
                <div className="surveys-item__content-info">
                  <Row
                    justify="space-between"
                    align="bottom"
                    className="surveys-item"
                  >
                    <Row
                      justify="space-between"
                      align="bottom"
                      className="surveys-item"
                    >
                      <Col span={24}>
                        <h2 className="pad-main_title v1-h">
                          {item?.pad.name}
                        </h2>
                      </Col>
                    </Row>
                    <Col span={16} lg={16} md={24} sm={24} xs={24}>
                      <div className="surveys-item__info-box">
                        <Row
                          justify="start"
                          className="surveys-item__info-meta"
                        >
                          <Col>
                            <span className="pad-main_meta-category">
                              {getPadIcon(
                                item?.pad?.category_id,
                                getPadCategoryName(item?.pad?.category_id),
                              )}
                              <span className="text ml-1">
                                {getPadCategoryName(item?.pad?.category_id)}
                              </span>
                            </span>
                          </Col>
                          <Col>
                            <span className="pad-main_meta-raiting">
                              <span className="icon mr-2">
                                <img src={Star} alt={item?.pad.rating} />
                              </span>
                              <span className="text">{item?.pad.rating}</span>
                            </span>
                          </Col>
                          <Col>
                            <span className="pad-main_meta-reviews">
                              <span className="icon mr-2">
                                <img
                                  src={Comment}
                                  alt={item?.pad.comments_count}
                                />
                              </span>
                              <span className="text">
                                {item?.pad.comments_count}
                              </span>
                            </span>
                          </Col>
                          <Col>
                            <span className="pad-main_meta-fee">
                              <span className="icon mr-2">
                                <img src={Fee} alt={item?.pad.landing_fees} />
                              </span>
                              <span className="text">
                                $ - {'$'.repeat(item?.pad.landing_fees) || '$'}
                              </span>
                            </span>
                          </Col>
                        </Row>
                        {item?.survey_status === SURVEY_STARTED ? (
                          <div className="text mt-2">
                            Help pilots by getting more information about your
                            Pads. Complete 360 Survey
                          </div>
                        ) : (
                          <>
                            {item?.expires_at && (
                              <Tooltip title="Your survey is over a year old and has expired and no longer available to pilots, please make any edit via the survey wizard to renew it.">
                                <div className="flex-center-center pointer mt-3 mb-1">
                                  <ExclamationCircleOutlined
                                    style={{
                                      color: '#b51452',
                                      fontSize: '20px',
                                    }}
                                  />
                                  <div
                                    className="ml-2"
                                    style={{ color: '#b51452' }}
                                  >
                                    Expired
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                            <div className="pad-main_data">
                              <p className="pad-main_data-started">
                                Survey Started on{' '}
                                {getStringRedactionData(item?.started_at)}
                              </p>
                              <p className="pad-main_data-updated">
                                Updated on{' '}
                                {getStringRedactionData(item?.updated_at)}
                              </p>
                            </div>
                            <div className="pad-main_progress">
                              <ProgressLine
                                maxSteps={7}
                                step={
                                  SURVEY_COMPLETED === item?.survey_status
                                    ? 7
                                    : item?.survey_completed_steps_count || 0
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col
                      span={8}
                      lg={8}
                      md={24}
                      sm={24}
                      xs={24}
                      className="surveys-item__btn-row"
                    >
                      {item?.survey_status === SURVEY_STARTED && (
                        <Button
                          type="primary"
                          className="pad-survey-btn"
                          onClick={() => redirectToSurvey(item?.id)}
                        >
                          Take Pad Survey
                        </Button>
                      )}
                      {item?.survey_status === SURVEY_IN_PROGRESS && (
                        <>
                          <Button
                            type="default"
                            className="pad-survey-btn red"
                            onClick={() => redirectToSurvey(item?.id)}
                          >
                            Continue Survey
                          </Button>
                          <Button
                            type="default"
                            className="pad-survey-btn red"
                            onClick={() => downloadPDF(item?.id)}
                            loading={
                              isDownloading && PDFDownloadId === item?.id
                            }
                          >
                            Download PDF
                          </Button>
                        </>
                      )}
                      {item?.survey_status === SURVEY_COMPLETED && (
                        <>
                          <Button
                            type="default"
                            className="pad-survey-btn red"
                            onClick={() => redirectToSurvey(item?.id)}
                          >
                            Edit Survey
                          </Button>
                          <Button
                            type="default"
                            className="pad-survey-btn red"
                            onClick={() => downloadPDF(item?.id)}
                            loading={
                              isDownloading && PDFDownloadId === item?.id
                            }
                          >
                            Download PDF
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        ))
      ) : (
        <Empty />
      )}
    </>
  );
};

MySurveys.defaultProps = {
  surveyList: {},
};

MySurveys.propTypes = {
  surveyList: PropTypes.array,
  setSurveyList: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default MySurveys;
